import React, { Component } from "react";
import { Container, Alert } from "react-bootstrap";
import api from "./api";
import queryString from "querystring";

export default class CheckTask extends Component {
  constructor(props) {
    super(props);
    const search = this.props.location.search;
    let taskId = null;
    if (search) {
      let params = queryString.parse(this.props.location.search);
      taskId = params.id || null;
    }
    this.state = { taskState: {}, requestFlag: false, taskId: taskId };
  }

  componentDidMount() {
    this.setRequestFlag(true);
    api
      .checkTaskState(this.state.taskId)
      .then((response) => {
        return response.status === 200 ? response.json() : null;
      })
      .then((task) => {
        this.setState({
          taskState: task || { state: "error" },
          requestFlag: false,
        });
      })
      .catch((e) => {
        this.setRequestFlag(false);
        console.log(e);
      });
  }

  setRequestFlag(flag) {
    this.setState(() => {
      return { requestFlag: flag };
    });
  }

  getStatus() {
    const state = this.state.taskState.state;
    if (state === "success") {
      return (
        <Alert variant="success">
          <Alert.Heading>Facebook data deleted successfully.</Alert.Heading>
        </Alert>
      );
    } else if (state === "error") {
      return (
        <Alert variant="danger">
          <Alert.Heading>Facebook data deletion error.</Alert.Heading>
        </Alert>
      );
    } else {
      return (
        <Alert variant="info">
          <Alert.Heading>Checking deletion request state...</Alert.Heading>
        </Alert>
      );
    }
  }

  render() {
    return <Container>{this.getStatus()}</Container>;
  }
}
