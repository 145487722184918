import React, { Component } from "react";
import { Container, Nav } from "react-bootstrap";
import Header from "./Header";

export default class PrivacyPolicy extends Component {
  emailLink() {
    return (
      <>
        <span> </span>
        <a
          href="mailto:info@luckyspingames.com"
          target="_blank"
          rel="noreferrer"
        >
          info@luckyspingames.com
        </a>
      </>
    );
  }

  createLink(index, text) {
    return <Nav.Link href={`#p${index}`}>{text}</Nav.Link>;
  }

  render() {
    return (
      <>
        <Header />
        <Container className="mt-4">
          <h1 className="display-4 text-center">PRIVACY POLICY</h1>
          <hr />
          <p>
            Thank you for choosing to be part of our community at Lucky Spin
            Games Inc. (“we”, “us”, or “our”). We are committed to protecting
            your personal information and your right to privacy. If you have any
            questions or concerns about our policy, or our practices with
            regards to your personal information, please contact us at
            {this.emailLink()}
          </p>
          <p>
            When you visit our mobile application, and use our services, you
            trust us with your personal information. We take your privacy very
            seriously. In this privacy notice, we describe our privacy policy.
            We seek to explain to you in the clearest way possible what
            information we collect, how we use it and what rights you have in
            relation to it. We hope you take some time to read through it
            carefully, as it is important. If there are any terms in this
            privacy policy that you do not agree with, please discontinue use of
            our Apps and our services.
          </p>
          <p>
            This privacy policy applies to all information collected through our
            mobile application, ("<b>Apps</b>"), and/or any related services,
            sales, marketing or events (we refer to them collectively in this
            privacy policy as the "<b>Sites</b>").
          </p>
          <p>
            Please read this privacy policy carefully as it will help you make
            informed decisions about sharing your personal information with us.
          </p>
          <Container>
            <h4 className="ml-4">TABLE OF CONTENTS</h4>
            <Nav className="flex-column" variant="tabs">
              {this.createLink(1, "WHAT INFORMATION DO WE COLLECT?")}
              {this.createLink(2, "HOW DO WE USE YOUR INFORMATION?")}
              {this.createLink(
                3,
                "WILL YOUR INFORMATION BE SHARED WITH ANYONE?"
              )}
              {this.createLink(4, "WHO WILL YOUR INFORMATION BE SHARED WITH?")}
              {this.createLink(5, "HOW DO WE HANDLE YOUR SOCIAL LOGINS?")}
              {this.createLink(
                6,
                "IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?"
              )}
              {this.createLink(
                7,
                "WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?"
              )}
              {this.createLink(8, "HOW LONG DO WE KEEP YOUR INFORMATION?")}
              {this.createLink(9, "HOW DO WE KEEP YOUR INFORMATION SAFE?")}
              {this.createLink(10, "DO WE COLLECT INFORMATION FROM MINORS?")}
              {this.createLink(11, "WHAT ARE YOUR PRIVACY RIGHTS?")}
              {this.createLink(12, "CONTROLS FOR DO-NOT-TRACK FEATURES")}
              {this.createLink(
                13,
                "DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?"
              )}
              {this.createLink(14, "DO WE MAKE UPDATES TO THIS POLICY?")}
              {this.createLink(15, "HOW CAN YOU CONTACT US ABOUT THIS POLICY?")}
            </Nav>
          </Container>
          <br />
          <p id="p1">Personal information you disclose to us</p>
          <p>
            <i>
              <b>In Short:</b> We collect personal information that you provide
              to us such as name, address, contact information, passwords and
              security data, and social media login data.
            </i>
            <br />
            We collect personal information that you voluntarily provide to us
            when expressing an interest in obtaining information about us or our
            products and services, when participating in activities on the Apps
            or otherwise contacting us.
            <br />
            The personal information that we collect depends on the context of
            your interactions with us and the Apps, the choices you make and the
            products and features you use. The personal information we collect
            can include the following:
            <br />
            <b>Name and Contact Data.</b> We collect your first and last name,
            email address, postal address, phone number, and other similar
            contact data.
            <br />
            <b>Credentials.</b> We collect passwords, password hints, and
            similar security information used for authentication and account
            access.
            <br />
            <b>Social Media Login Data.</b> In some of our apps, we can provide
            you with the option to register using social media account details,
            like your Facebook, Twitter or other social media account. If you
            choose to register in this way, we will collect the Information
            described in the section called "HOW DO WE HANDLE YOUR SOCIAL
            LOGINS" below.
            <br />
            All personal information that you provide to us must be true,
            complete and accurate, and you must notify us of any changes to such
            personal information.
            <br /> Information automatically collected
            <br />
            <i>
              <b>In Short:</b> Some information – such as IP address and/or
              browser and device characteristics – is collected automatically
              when you visit our Apps.
            </i>
            <br />
            We automatically collect certain information when you visit, use or
            navigate the Apps. This information does not reveal your specific
            identity (like your name or contact information) but may include
            device and usage information, such as your IP address, browser and
            device characteristics, operating system, language preferences,
            referring URLs, device name, country, location, information about
            how and when you use our Apps and other technical information. This
            information is primarily needed to maintain the security and
            operation of our Apps, and for our internal analytics and reporting
            purposes.
            <br />
            Information collected through our Apps
            <br />
            <i>
              <b>In Short:</b> We may collect information regarding your
              geo-location, mobile device, push notifications, when you use our
              apps.
            </i>
            <br />
            If you use our Apps, we may also collect the following information:
            <br /> Geo-Location Information. We may request access or permission
            to and track location-based information from your mobile device,
            either continuously or while you are using our mobile application,
            to provide location-based services or your registration address. If
            you wish to change our access or permissions, you may do so in your
            device’s settings.
            <br />
            Mobile Device Access. We may request access or permission to certain
            features from your mobile device, including your mobile device’s
            bluetooth, calendar, camera, microphone, reminders, sensors, and
            other features. If you wish to change our access or permissions, you
            may do so in your device’s settings.
            <br />
            Mobile Device Data. We may automatically collect device information
            (such as your mobile device ID, model and manufacturer), operating
            system, version information and IP address.
            <br />
            Push Notifications. We may request to send you push notifications
            regarding your account or the mobile application. If you wish to
            opt-out from receiving these types of communications, you may turn
            them off in your device’s settings. Information collected from other
            sources
            <br />
            <i>
              <b>In Short:</b> We may collect limited data from public
              databases, marketing partners, social media platforms, and other
              outside sources.
            </i>
            <br />
            We may obtain information about you from other sources, such as
            public databases, joint marketing partners, social media platforms
            (such as Facebook), as well as from other third parties. Examples of
            the information we receive from other sources include: social media
            profile information (your name, gender, birthday, email, current
            city, state and country, user identification numbers for your
            contacts, profile picture URL and any other information that you
            choose to make public); marketing leads and search results and
            links, including paid listings (such as sponsored links).
          </p>
          <h3 id="p2">HOW DO WE USE YOUR INFORMATION?</h3>
          <p>
            <i>
              <b>In Short:</b> We process your information for purposes based on
              legitimate business interests, the fulfillment of our contract
              with you, compliance with our legal obligations, and/or your
              consent.
            </i>
            <br />
            We use personal information collected via our Apps for a variety of
            business purposes described below. We process your personal
            information for these purposes in reliance on our legitimate
            business interests ("Business Purposes"), in order to enter into or
            perform a contract with you ("Contractual"), with your consent
            ("Consent"), and/or for compliance with our legal obligations
            ("Legal Reasons"). We indicate the specific processing grounds we
            rely on next to each purpose listed below.
            <br />
            We use the information we collect or receive:
            <br />
            <b>To facilitate account creation and logon process.</b> If you
            choose to link your account with us to a third party account *(such
            as your Google or Facebook account), we use the information you
            allowed us to collect from those third parties to facilitate account
            creation and logon process. See the section below headed "HOW DO WE
            HANDLE YOUR SOCIAL LOGINS" for further information.
            <br />
            <b>To post testimonials.</b> We post testimonials on our Apps that
            may contain personal information. Prior to posting a testimonial, we
            will obtain your consent to use your name and testimonial. If you
            wish to update, or delete your testimonial, please contact us
            info@luckyspingames.com and be sure to include your name,
            testimonial location, and contact information.
            <br />
            <b>Deliver targeted advertising to you.</b> We may use your
            information to develop and display content and advertising (and work
            with third parties who do so) tailored to your interests and/or
            location and to measure its effectiveness.
            <br />
            <b>For other Business Purposes.</b> We may use your information for
            other Business Purposes, such as data analysis, identifying usage
            trends, determining the effectiveness of our promotional campaigns
            and to evaluate and improve our Apps, products, services, marketing
            and your experience.
          </p>
          <h3 id="p3">WILL YOUR INFORMATION BE SHARED WITH ANYONE?</h3>
          <p>
            <i>
              <b>In Short</b>: We only share information with your consent, to
              comply with laws, to protect your rights, or to fulfill business
              obligations.
            </i>
            <br />
            We may process or share data based on the following legal basis:
            <br />
            <b>Consent:</b> We may process your data if you have given us
            specific consent to use your personal information in a specific
            purpose.
            <br />
            <b>Legitimate Interests:</b> We may process your data when it is
            reasonably necessary to achieve our legitimate business interests.
            <br />
            <b>Performance of a Contract:</b> Where we have entered into a
            contract with you, we may process your personal information to
            fulfill the terms of our contract.
            <br />
            <b>Legal Obligations:</b> We may disclose your information where we
            are legally required to do so in order to comply with applicable
            law, governmental requests, a judicial proceeding, court order, or
            legal process, such as in response to a court order or a subpoena
            (including in response to public authorities to meet national
            security or law enforcement requirements).
            <br />
            <b>Vital Interests:</b> We may disclose your information where we
            believe it is necessary to investigate, prevent, or take action
            regarding potential violations of our policies, suspected fraud,
            situations involving potential threats to the safety of any person
            and illegal activities, or as evidence in litigation in which we are
            involved.
            <br />
            More specifically, we may need to process your data or share your
            personal information in the following situations:
            <br />
            <b>
              Vendors, Consultants and Other Third-Party Service Providers.{" "}
            </b>
            We may share your data with third party vendors, service providers,
            contractors or agents who perform services for us or on our behalf
            and require access to such information to do that work. Examples
            include: payment processing, data analysis, email delivery, hosting
            services, customer service and marketing efforts. We may allow
            selected third parties to use tracking technology on the Apps, which
            will enable them to collect data about how you interact with the
            Apps over time. This information may be used to, among other things,
            analyze and track data, determine the popularity of certain content
            and better understand online activity. Unless described in this
            Policy, we do not share, sell, rent or trade any of your information
            with third parties for their promotional purposes.
            <br />
            <b>Business Transfers.</b> We may share or transfer your information
            in connection with, or during negotiations of, any merger, sale of
            company assets, financing, or acquisition of all or a portion of our
            business to another company.
            <br />
            <b>Business Partners.</b> We may share your information with our
            business partners to offer you certain products, services or
            promotions.
            <br />
            <b>Other Users.</b> When you share personal information (for
            example, by posting comments, contributions or other content to the
            Apps) or otherwise interact with public areas of the Apps, such
            personal information may be viewed by all users and may be publicly
            distributed outside the Apps in perpetuity. If you interact with
            other users of our Apps and register through a social network (such
            as Facebook), your contacts on the social network will see your
            name, profile photo, and descriptions of your activity. Similarly,
            other users will be able to view descriptions of your activity,
            communicate with you within our Apps, and view your profile.
          </p>
          <h3 id="p4">WHO WILL YOUR INFORMATION BE SHARED WITH?</h3>
          <p>
            <i>
              <b>In Short:</b> We may only share information with the following
              third parties.
            </i>
            <br />
            We may only share and disclose your information with the following
            third parties. We have categorized each party so that you may be
            easily understand the purpose of our data collection and processing
            practices. If we have processed your data based on your consent and
            you wish to revoke your consent, please contact us.
            <br />
            Advertising, Direct Marketing, and Lead Generation
            <br />
            Adcolony, AppLovin, Facebook Audience Network, Google AdSense, Unity
            Ads, Snap, Vungle, AdMob, Mopub, Ironsource, Tapjoy, Social Media
            Sharing and Advertising
            <br />
            Facebook advertising and Instagram advertising
            <br />
            Web and Mobile Analytics
            <br />
            Adjust, Appsflyer, Facebook Analytics, Google Analytics, Unity
            Analytics, Amplitude
            <br />
            Mobile development platforms
            <br />
            Google Firebase
          </p>
          <h3 id="p5">HOW DO WE HANDLE YOUR SOCIAL LOGINS?</h3>
          <p>
            <i>
              <b>In Short:</b> If you choose to register or log in to our apps
              using a social media account, we may have access to certain
              information about you. Our Apps offer you the ability to register
              and login using your third party social media account details
              (like your Facebook or Twitter logins). Where you choose to do
              this, we will receive certain profile information about you from
              your social media provider. The profile Information we receive may
              vary depending on the social media provider concerned, but will
              often include your name, e-mail address, friends list, profile
              picture as well as other information you choose to make public.
            </i>
            <br />
            We will use the information we receive only for the purposes that
            are described in this privacy policy or that are otherwise made
            clear to you on the Apps. Please note that we do not control, and
            are not responsible for, other uses of your personal information by
            your third party social media provider. We recommend that you review
            their privacy policy to understand how they collect, use and share
            your personal information, and how you can set your privacy
            preferences on their sites and apps.
          </p>
          <h3 id="p6">IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</h3>
          <p>
            <i>
              <b>In Short:</b> We may transfer, store, and process your
              information in countries other than your own.
            </i>
            <br />
            Our servers are located in United States. If you are accessing our
            Apps from outside United States, please be aware that your
            information may be transferred to, stored, and processed by us in
            our facilities and by those third parties with whom we may share
            your personal information (see "WILL YOUR INFORMATION BE SHARED WITH
            ANYONE?" above), in United Kingdom, and other countries.
            <br />
            If you are a resident in the European Economic Area, then these
            countries may not have data protection or other laws as
            comprehensive as those in your country. We will however take all
            necessary measures to protect your personal information in
            accordance with this privacy policy and applicable law.
          </p>
          <h3 id="p7">WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?</h3>
          <p>
            <i>
              <b>In Short:</b> We are not responsible for the safety of any
              information that you share with third-party providers who
              advertise, but are not affiliated with, our apps.
            </i>
            <br />
            The Apps may contain advertisements from third parties that are not
            affiliated with us and which may link to other websites, online
            services or mobile applications. We cannot guarantee the safety and
            privacy of data you provide to any third parties. Any data collected
            by third parties is not covered by this privacy policy. We are not
            responsible for the content or privacy and security practices and
            policies of any third parties, including other websites, services or
            applications that may be linked to or from the Apps. You should
            review the policies of such third parties and contact them directly
            to respond to your questions.
          </p>
          <h3 id="p8">HOW LONG DO WE KEEP YOUR INFORMATION?</h3>
          <p>
            <i>
              <b>In Short:</b> We keep your information for as long as necessary
              to fulfill the purposes outlined in this privacy policy unless
              otherwise required by law. We will only keep your personal
              information for as long as it is necessary for the purposes set
              out in this privacy policy, unless a longer retention period is
              required or permitted by law (such as tax, accounting or other
              legal requirements). No purpose in this policy will require us
              keeping your personal information for longer than 6 months.
            </i>
            <br />
            When we have no ongoing legitimate business need to process your
            personal information, we will either delete or anonymize it, or, if
            this is not possible (for example, because your personal information
            has been stored in backup archives), then we will securely store
            your personal information and isolate it from any further processing
            until deletion is possible.
          </p>
          <h3 id="p9">HOW DO WE KEEP YOUR INFORMATION SAFE?</h3>
          <p>
            <i>
              <b>In Short:</b> We aim to protect your personal information
              through a system of organisational and technical security
              measures. We have implemented appropriate technical and
              organisational security measures designed to protect the security
              of any personal information we process. However, please also
              remember that we cannot guarantee that the internet itself is 100%
              secure. Although we will do our best to protect your personal
              information, transmission of personal information to and from our
              Apps is at your own risk. You should only access the services
              within a secure environment.
            </i>
          </p>
          <h3 id="p10">DO WE COLLECT INFORMATION FROM MINORS?</h3>
          <p>
            <i>
              <b>In Short:</b> We do not knowingly collect data from or market
              to children under 13 years of age.
            </i>
            <br />
            We do not knowingly solicit data from children under 13 years of
            age. By using the Apps, you represent that you are at least 13 or
            that you are the parent or guardian of such a minor and consent to
            such minor dependent’s use of the Apps. If we learn that personal
            information from users less than 13 years of age has been collected,
            we will deactivate the account and take reasonable measures to
            promptly delete such data from our records. If you become aware of
            any data we have collected from children under age 13, please
            contact us at
            {this.emailLink()}
          </p>
          <h3 id="p11">WHAT ARE YOUR PRIVACY RIGHTS?</h3>
          <p>
            <i>
              <b>In Short:</b> In some regions, such as the European Economic
              Area, you have rights that allow you greater access to and control
              over your personal information. You may review, change, or
              terminate your account at any time.
            </i>
            <br />
            In some regions (like the European Economic Area), you have certain
            rights under applicable data protection laws. These may include the
            right (I) to request access and obtain a copy of your personal
            information, (II) to request rectification or erasure; (III) to
            restrict the processing of your personal information; and (IV) if
            applicable, to data portability. In certain circumstances, you may
            also have the right to object to the processing of your personal
            information. To make such a request, please use the contact details
            provided below. We will consider and act upon any request in
            accordance with applicable data protection laws.
            <br />
            If we are relying on your consent to process your personal
            information, you have the right to withdraw your consent at any
            time. Please note however that this will not affect the lawfulness
            of the processing before its withdrawal.
            <br />
            If you are resident in the European Economic Area and you believe we
            are unlawfully processing your personal information, you also have
            the right to complain to your local data protection supervisory
            authority. You can find their contact details here:{" "}
            <a
              href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
              target="_blank"
              rel="noreferrer"
            >
              http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
            </a>
          </p>
          <h3 id="p12">CONTROLS FOR DO-NOT-TRACK FEATURES</h3>
          <p>
            Most web browsers and some mobile operating systems and mobile
            applications include a Do-Not-Track (“DNT”) feature or setting you
            can activate to signal your privacy preference not to have data
            about your online browsing activities monitored and collected. No
            uniform technology standard for recognizing and implementing DNT
            signals has been finalized. As such, we do not currently respond to
            DNT browser signals or any other mechanism that automatically
            communicates your choice not to be tracked online. If a standard for
            online tracking is adopted that we must follow in the future, we
            will inform you about that practice in a revised version of this
            Privacy Policy.
          </p>
          <h3 id="p13">
            DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
          </h3>
          <p>
            <i>
              <b>In Short:</b> Yes, if you are a resident of California, you are
              granted specific rights regarding access to your personal
              information.
            </i>
            <br />
            California Civil Code Section 1798.83, also known as the “Shine The
            Light” law, permits our users who are California residents to
            request and obtain from us, once a year and free of charge,
            information about categories of personal information (if any) we
            disclosed to third parties for direct marketing purposes and the
            names and addresses of all third parties with which we shared
            personal information in the immediately preceding calendar year. If
            you are a California resident and would like to make such a request,
            please submit your request in writing to us using the contact
            information provided below.
            <br />
            If you are under 18 years of age, reside in California, and have a
            registered account with the Apps, you have the right to request
            removal of unwanted data that you publicly post on the Apps. To
            request removal of such data, please contact us using the contact
            information provided below, and include the email address associated
            with your account and a statement that you reside in California. We
            will make sure the data is not publicly displayed on the Apps, but
            please be aware that the data may not be completely or
            comprehensively removed from our systems.
            <br />
            Also, subject to certain limitations, the California Consumer
            Privacy Act provides California consumers the right to request to
            know more details about the categories or specific pieces of
            personal information we collect (including how we use and disclose
            this information), to delete their personal information, to opt-out
            of any “sales” that may be occurring, and to not be discriminated
            against for exercising these rights.
            <br />
            California consumers may make a request pursuant to their rights
            under the CCPA by contacting us at{this.emailLink()} We will verify
            your request and inform you accordingly. You may also designate an
            authorized agent to exercise these rights on your behalf.
          </p>
          <h3 id="p14">DO WE MAKE UPDATES TO THIS POLICY?</h3>
          <p>
            <i>
              <b>In Short:</b> Yes, we will update this policy as necessary to
              stay compliant with relevant laws.
            </i>
            <br />
            We may update this privacy policy from time to time. The updated
            version will be indicated by an updated “Revised” date and the
            updated version will be effective as soon as it is accessible. If we
            make material changes to this privacy policy, we may notify you
            either by prominently posting a notice of such changes or by
            directly sending you a notification. We encourage you to review this
            privacy policy frequently to be informed of how we are protecting
            your information.
          </p>
          <h3 id="p15">HOW CAN YOU CONTACT US ABOUT THIS POLICY?</h3>
          <p>
            If you have questions or comments about this policy, you may email
            us
            {this.emailLink()}
            <br />
            Or by post:
            <br />
            Lucky Spin Games Inc.
            <br />
            848 N Rainbow Blvd 2527
            <br />
            Las Vegas, Nevada 89107
            <br />
            United States
          </p>
          <h4>
            HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
          </h4>
          <p>
            Based on the laws of some countries, you may have the right to
            request access to the personal information we collect from you,
            change that information, or delete it in some circumstances. To
            request to review, update, or delete your personal information,
            please submit a request. We will respond to your request within 30
            business days.
          </p>
          <p>
            A link to: <a href="/terms">Terms of Use</a>
          </p>
        </Container>
      </>
    );
  }
}
