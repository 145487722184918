import React, { Component } from "react";
import { Container } from "react-bootstrap";
import Header from "./Header";

export default class MergeGame extends Component {
  render() {
    return (
      <>
        <Header />
        <Container className="mt-4">
          <h1 className="display-4 text-center">TERMS OF USE</h1>
          <hr />
          <p>
            <b>1.1</b> Our Services may include in-app purchases that allow you
            to buy things such as virtual currency to use within the Service
            (“In-App Purchases”). If it does, it will not be necessary to make
            any In-App Purchases in order to use the Service. Whilst you cannot
            switch off In-App Purchases from being offered to you within the
            Service and there is no cap on the number of In-App Purchases you
            can make, you can switch off/manage your ability to complete In-App
            Purchases by altering the settings on the device you use to play the
            Service. You acknowledge and agree that you are fully responsible
            for managing your In-App Purchases and the amount you spend on
            In-App Purchases within the Service.
          </p>
          <p>
            <b>1.2</b> If you are under 18 then you must have your parents’ or
            guardians’ permission to make any In-App Purchases. By completing an
            In-App Purchase, you are confirming to us that you have any and all
            permission that may be necessary in order to allow you to make that
            In-App Purchase.
          </p>
          <p>
            <b>1.3</b> More information about how you may be able to switch off
            and/or manage In-App Purchases using your device may be set out in
            the App Store Provider’s terms and conditions/EULA, in your device’s
            help settings or online. If you are a parent or guardian of someone
            under the age of 18 we recommend that you consider any parental
            controls that may be provided by the App Store Provider if you are
            concerned that your child may make excessive In-App Purchases.
          </p>
          <p>
            <b>1.4</b> In-App Purchases can only be consumed within the Service.
            If you make an In-App Purchase, that In-App Purchase cannot be
            cancelled after you have initiated its download. In-App Purchases
            cannot be redeemed for cash or other consideration or otherwise
            transferred. If you make any In-App Purchases in the Service, then
            the App Store Provider’s terms and conditions/EULA applicable to
            In-App Purchases will also apply.
          </p>
          <p>
            <b>1.5</b> If any In-App Purchase is not successfully downloaded or
            does not work once it has been successfully downloaded, we will,
            after becoming aware of the fault or being notified of the fault by
            you, investigate the reason for the fault. We will act reasonably in
            deciding whether to provide you with a replacement In-App Purchase
            or issue you with a patch to repair the fault. In no event will we
            charge you anything further to replace or repair the In-App
            Purchase. In the unlikely event that we are unable to replace or
            repair the relevant In-App Purchase or are unable to do so within a
            reasonable period of time and without significant inconvenience to
            you, we will authorize the App Store Provider to refund you an
            amount up to the cost of the relevant In-App Purchase.
            Alternatively, if you wish to request a refund, you may do so by
            contacting the App Store Provider directly.
          </p>
          <p>
            <b>1.6</b> You acknowledge and agree that all billing and
            transaction processes are handled by the App Store Provider from
            whose platform you downloaded the Service and are governed by the
            App Store Provider’s terms and conditions/EULA. If you have any
            payment related issues with In-App Purchases then you need to
            contact the App Store Provider directly.
          </p>
          <p>
            A link to: <a href="/privacy-policy">Privacy Policy</a>
          </p>
        </Container>
      </>
    );
  }
}
