module.exports = {
  checkTaskState: (taskId) => {
    const url = `https://aew8oht9j3.execute-api.us-east-1.amazonaws.com/prod/facebook/data-deletion?id=${taskId}`;
    const params = {
      method: "GET",
      crossDomain: true,
    };
    return fetch(url, params);
  },
};
