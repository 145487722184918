import React, { Component } from "react";
import { Container } from "react-bootstrap";
import imageTop from "./mth/top.webp";
import imageGear from "./mth/gear.webp";
import imageDelete from "./mth/delete.webp";
import "./mth/style.css";

export default class Merge2AccountDeletion extends Component {
  render() {
    return (
      <Container className="mt-4">
        <h1>HOW TO DELETE YOUR ACCOUNT</h1>
        <h2>Merge Treasure Hunt</h2>
        <div class="image-container">
          <img src={imageTop} alt="Merge Treasure Hunt" />
        </div>
        <p>
          At Merge Treasure Hunt, we value your privacy and strive to provide a
          safe and secure gaming environment. We have established a
          comprehensive user deletion policy to ensure that your personal
          information is handled responsibly.
        </p>
        <h3>Contact Us to Delete Your User Data</h3>
        <p>
          Account deletion means the permanent deletion of your game account and
          all of the data associated with it. Deletion requests can be initiated
          through the app or by contacting our support team. Deletion process
          can take several days to be completed and once done, it is
          irreversible. If you are unable to start the deletion from the app,
          you can contact our support team by submitting a request to{" "}
          <a href="mailto:info@luckyspingames.com">info@luckyspingames.com</a>
        </p>
        <p>
          In certain cases we reserve the right to ask additional information to
          verify ownership of the account before initiating account deletion.
        </p>
        <p>
          If at any point you wish to cancel the deletion request, please make
          sure to let us know within due time, however we can not guarantee that
          your account has not been permanently deleted.
        </p>
        <h3>Deleting Your User Data from the Game</h3>
        <p>
          If you wish to delete your account and all associated data through the
          Merge Treasure Hunt app, you can do so by following the steps below:
        </p>
        <ul>
          <li>Open the Merge Treasure Hunt game on your device.</li>
          <li>
            Navigate to the settings menu by tapping on the gear icon in the top
            right corner.
          </li>
          <div class="image-container">
            <img src={imageGear} alt="Merge Treasure Hunt" />
          </div>
          <li>
            Tap on "Delete Account" and follow the on-screen instructions.
          </li>
          <div class="image-container">
            <img src={imageDelete} alt="Merge Treasure Hunt" />
          </div>
          <li>
            Confirm your decision to delete your account. Note that this action
            is irreversible.
          </li>
        </ul>
        <h3>Data Retention and Deletion</h3>
        <p>
          Upon receiving a deletion request, we will take immediate steps to
          remove your personal data from our systems. This process may take up
          to 30 days to complete. During this period, your account will be
          deactivated, and you will no longer have access to the game or any
          associated services.
        </p>
        <p>
          Please note that certain data may be retained for legal or regulatory
          reasons, such as compliance with financial or tax laws. In such cases,
          the data will be securely stored and protected in accordance with
          applicable regulations.
        </p>
        <h3>Contact Us</h3>
        <p>
          If you have any questions or concerns regarding our user deletion
          policy or need assistance with the deletion process, please contact
          our support team at{" "}
          <a href="mailto:info@luckyspingames.com">info@luckyspingames.com</a>.
        </p>
      </Container>
    );
  }
}
