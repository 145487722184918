import React, { Component } from "react";
import { Nav, Navbar } from "react-bootstrap";

export default class Header extends Component {
  render() {
    return (
      <Navbar
        // fixed="bottom"
        className="justify-content-center"
      >
        <Nav.Link href="/privacy-policy">Privacy Policy</Nav.Link>
        <Nav.Link href="/terms">Terms of Use</Nav.Link>
      </Navbar>
    );
  }
}
