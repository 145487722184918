import React, { Component } from "react";
import { Container, Jumbotron } from "react-bootstrap";
import Footer from "./Footer";

export default class Home extends Component {
  render() {
    return (
      <Container className="mt-4">
        <Jumbotron fluid>
          <h1 className="display-2 text-center">Lucky Spin Games, Inc</h1>
        </Jumbotron>
        <Footer />
      </Container>
    );
  }
}
