import React, { Component } from "react";
import { Navbar, Nav } from "react-bootstrap";

export default class Header extends Component {
  render() {
    return (
      <Navbar bg="light" variant="light">
        <Nav.Link href="/">Home</Nav.Link>
      </Navbar>
    );
  }
}
