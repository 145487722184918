import Home from "./Home";
import PrivacyPolicy from "./PrivacyPolicy";
import Terms from "./Terms";
import CheckTask from "./CheckTask";
import { Switch, Route, Redirect } from "react-router-dom";
import Merge2AccountDeletion from "./Merge2AccountDeletion";

function App() {
  return (
    <div>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/privacy-policy" component={PrivacyPolicy} />
        <Route exact path="/terms" component={Terms} />
        <Route path="/check-state" component={CheckTask} />
        <Route
          path="/merge-treasure-hunt/account-deletion"
          component={Merge2AccountDeletion}
        />
        <Route render={() => <Redirect to="/" />} />
      </Switch>
    </div>
  );
}

export default App;
